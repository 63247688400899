import React from "react";

import {
  Breadcrumb,
  Container,
  CompanyProfileContents,
} from "../../components";
import Seo from "../../components/Seo";

const CompanyProfilePage = () => {
  return (
    <Container>
      <Seo
        title="会社概要"
        description="TKCホールディングの会社概要をご覧いただけます。"
      />
      <CompanyProfileContents />
      <Breadcrumb
        currentPage="Company Profile"
        currentSlug="/company/company-profile"
        parentPage="Company"
        parentSlug="/company"
      />
    </Container>
  );
};

export default CompanyProfilePage;
